
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/agents-search/[...dynamic]",
      function () {
        return require("private-next-pages/agents-search/[...dynamic].tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/agents-search/[...dynamic]"])
      });
    }
  