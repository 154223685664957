import React from 'react';
import Layout from 'components/layout';
import HeadData from 'components/head-data';
import AgentPage from 'components/agent-search/agent-profile';
import { AGENTS_ROUTE } from 'components/dynamic-page/route-matchers';
import { ThemeNames } from 'types/themes';
import { getThemeOverrideFromRequest } from 'utils/themes';
import { themes } from 'themes';

import type { GetServerSidePropsContext } from 'next';

export async function getServerSideProps(context: GetServerSidePropsContext) {
  const agentInfoFromQuery = context.query.dynamic && context.query.dynamic[0];
  const themeName = getThemeOverrideFromRequest(context.req);

  const getHeading = () => {
    const fullName = agentInfoFromQuery?.split('_')[0];
    const agentName = fullName?.replace('-', ' ');

    const theme = themes[themeName];
    if (theme) {
      return {
        title: `${agentName} ${theme.title}`,
        metaDescription: theme.metaDescription,
      };
    }

    return {
      title: `${agentName} Real Estate Agent | eXp Realty®`,
      metaDescription: "Find real estate listings for sale in Canada. Search MLS real estate listings for homes, condos and properties on exprealty.ca, Canada's top real estate website.",
    };
  };

  if (!themeName || themeName === ThemeNames.ZOOCASA) {
    return {
      redirect: {
        permanent: true,
        destination: '/404',
      },
    };
  }

  return {
    props: {
      routeName: AGENTS_ROUTE,
      props: {
        headTags: getHeading(),
      },
    },
  };
}

interface Props {
  routeName: string;
  props: {
    headTags: {
      title: string;
      metaDescription: string;
    };
  };
}

export default function DynamicAgentPage({ props }: Props) {
  const { headTags } = props;

  return (
    <Layout>
      {headTags && <HeadData data={{ title: headTags.title, metaDescription: headTags.metaDescription }} />}
      <AgentPage />
    </Layout>
  );
}
